import './styles.scss';
import React,{useEffect, useState, Component} from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {openreels} from '../../../../Redux/action/setreels'
import { useDispatch, useSelector } from 'react-redux';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';



function Foot2(){
   
    const reel = useSelector((state)=>state.reels.value)
    const dispatch = useDispatch()

    function handleOpen(){
        dispatch(openreels())
        console.log(reel)
    }


    return(

        <div className='Foot' style={{pointerEvents:'none'}}>
           <div className='Icons'>
            <HomeOutlinedIcon style={{fontSize:30}}/>
            <SearchOutlinedIcon style={{fontSize:30}}/>
            {/*<VideoLibraryIcon style={{fontSize:30}} onClick={()=>window.location.replace("http://localhost:3000/reels")}/> */}
            <AddBoxOutlinedIcon style={{fontSize:30}}/>
            <VideoLibraryIcon style={{fontSize:30}} onClick={handleOpen}/>
            <AccountCircleOutlinedIcon style={{fontSize:30}}/>
            </div>
        </div>

    );
}

export default Foot2