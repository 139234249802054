import './style.scss';
import React,{useEffect, useState, useRef, Component, createElement, useMemo, isValidElement} from 'react';
import Button from '@mui/material/Button';
import Foot from '../Foot/FootReels'
import axios from 'axios';
import {closereels} from '../../../../Redux/action/setreels'
import {checkAdReels} from '../../../../Redux/action/setad_reels'
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Data from '../../../../data/reelsprod.json'
import AdData from '../../../../data/adsvid.json'
import { useInView  } from 'react-intersection-observer';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Loading from "../Pictures/loading.gif"
import Mute from '../Pictures/mute3.svg'
import PlayBtn from '../Pictures/play.svg'
import ReelAd from  '../videos/reelad.mp4'
import { register } from 'swiper/element/bundle';
import { useNavigate,Link,useParams, useSearchParams } from 'react-router-dom';
//icons

import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';

register();

function ReelsNew(){

   let down=false
   let up=false
   let move=false
   let firstPlay=false
   let reelsCount=Object.keys(Data.item).length-1
   let y
   let position=0;
   let videoMute=false
   let videoPause=false
 //  let observer=null
  let d0
   const [videoLoad, setVideoLoad]=React.useState(false)
   const [reelAvailable, setreelAvailable]=React.useState(false)
   const [searchParams] = useSearchParams();
   const adindex2 = searchParams.get('ad');
   const reelsAd = searchParams.get('v');
 
   const myElementRef = useRef(null);
   const reel0 = useRef(null);
   const circle_ref=useRef(null)
 
  
 


  // let reelsFormat=searchParams.get("rwf")
   //let reelsAd={ReelAd}
   let reelsFormat=20
   let adlist=[]

 
   let imgAd=document.createElement('img')
   

   
   imgAd.className="ReelImg"
   imgAd.id="imgAd"
   
   let test=Data.item[0].enclosure._attributes.url


      let Interval
      let videos=[]
     let swipe=true


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
      }); 

    const open = useSelector((state)=>state.reels.value)
    const checked = useSelector((state)=>state.adreels.value)

   const dispatch = useDispatch()

    const handleClose=()=>{
      clearInterval(Interval)
      setVideoLoad(false)
        dispatch(closereels())
       
        
    }

    function nextReels(){
      reelsCount++
      if(reelsCount>=Object.keys(Data.item).length){
        reelsCount--
    }
    
    
    }

    function previousReels(){
      reelsCount--
      
    }



     
    
    function Down(e){

     


      
    
        down=true
        up=false
       

     

    
      }


       
      
       function Up(e){
       
        firstPlay=true
        up=true
        down=false
        move=false
        swipe=true
        
       

         
       }

      
       function pausePreviousVideo(){
       
       }


      
        function Move(e){
        
        move=true
        swipe=false
        document.getElementById('playpause').style.display="none"
        videoPause=false
       }

       const handlePausePlay=(e)=>{

        /* 
        videoPause=!videoPause
        if(videoPause){
          e.target.pause()
          document.getElementById('playpause').style.display="block"
        }
        
       else if(!videoPause){
          e.target.play()
          document.getElementById('playpause').style.display="none"
        }
          */
       
         
       }

       const handlePlay=(e)=>{
     
        if(swipe)
        e.target.play()
      }

      /* 
       const handleMute =(e)=>{
       // setMute(!videoMute)
      
       videoMute=!videoMute
        e.target.muted=videoMute
        videoMute ? document.getElementById('mute').style.opacity=0.7: document.getElementById('mute').style.opacity=0
    
       }
*/
      
      

       

      
       const openVideos=async()=>{
       
        await new Promise((resolve,reject)=>{ 
         Interval=setInterval(()=>{
       
      
         if(reel0.current.readyState>=4){
          
          myElementRef.current.oncanplaythrough=function(){
            document.getElementById('screen').style.overflowY="scroll"
          }
          clearInterval(Interval)
          
          //setVideoLoad(true)
           
          hideLoader()
          
           reel0.current.play()
           document.getElementById('screen').style.pointerEvents="auto"
           
           resolve()
         }
         else{
        
         }
      
         
      
           },1000)
      
           
          })
      
      
          }
            
      
        const Launch=async()=>{
          
           openVideos()
          .then(result=>{
            setTimeout(() => {
              document.getElementById("ios_dialog").style.display="flex"
             
            }, 1000);
            
          })
          .catch(error=>{
           
          })
      
      
        }
      
        const Launch_ios=()=>{
          openVideos()
          .then(result=>{
            setTimeout(() => {
              document.getElementById("ios_dialog").style.display="flex"
              
            }, 1000);
            
          })
          .catch(error=>{
           
          })
         
        }
      
      
        const handeIosPlay=()=>{
      
        document.getElementById("ios_dialog").style.display="none"
        document.body.style.overflow = 'auto';
       
        PlayVideos()
        
      
        }
      
      
      
      
        
      
          
          function PlayVideos(){
      
            reel0.current.play()
            const allreels=document.querySelectorAll('.Reel')
            let videoFocus=null;
            const swiperEl = document.querySelector('swiper-container');
          
            const callback=function(entries, observer){
          
              
                    
              entries.forEach(entry=>{
               
               
                let {target}=entry
          
              
               
                
                    videoFocus=target
                  
                   
                    if(videoFocus!=null){
                
                      if(videoFocus.currentTime > 0 && !videoFocus.paused){
                        //target.muted=true
                        videoFocus.pause()
                      }
                    
                    }
          
                if(entry.isIntersecting && entry.intersectionRatio>=0.5){
              
                  target.load()
                  target.muted=false
                  target.currentTime=0
                 target.play()

                 target.addEventListener('waiting',showLoader)
                 target.addEventListener('canplay',hideLoader)
                
                
                 allreels.forEach((item,index)=>{
                  
                  if(index!=swiperEl.swiper.activeIndex){
                   item.muted=true
                   
                  }
                  
               })
        
                 //swipe=true
              
                  
                }
                
              })
             
            }
            
            
            const options={
              threshold: 0.6,
             
              
              
            }
            
              const observer=new IntersectionObserver(callback,options)
             
              
              
               
               allreels.forEach((all)=>{
               videos.push(all)
               all.muted=true
              // all.play()
              //all.pause()
                observer.observe(all)
              
               
               })
               
               
         
            
             
            }
   
            const showLoader=()=>{
   
              circle_ref.current.style.display="flex"
            
            }
              
            const hideLoader=()=>{
              circle_ref.current.style.display="none"
          
              
             
            }


  function check(element) {
    return new Promise((resolve,reject) => {
     if(element!=null){

        resolve();
       
      }
      else{
        reject()
      }
    });
  }

  async function checkElement(){
    const element=reel0.current
    await check(element)
    .then(result=>{

      setreelAvailable(true)
    })
    .catch(error=>{
      checkElement()
    })
  }





  useEffect(()=>{

    const swiperEl = document.querySelector('swiper-container');


    swiperEl.swiper.on('reachEnd',()=>{
      swiperEl.swiper.allowSlideNext=false
    })

    swiperEl.swiper.on('fromEdge', () => {
     
      swiperEl.swiper.allowSlideNext = true; 
    });
    document.body.style.overflow = 'hidden';

    if(/Android/.test(window.navigator.userAgent)){
      
      
     
      //checkElement()
      if(reelAvailable===true){
        
       // Launch()
      
       
      }
  
  
    }
    else if(!/Android/.test(window.navigator.userAgent)){
      /*Тест для IOS*/

     // checkElement()
      if(reelAvailable===true){
        
        
       // Launch_ios()
      }
     
    }
   
   },[])
  


    return(
   
      <>
        <div className="reels_Main" id="main">
     
       

        {/*  {iosDialogstate && <IosDialog onClick={handeIosPlay}/>}*/}
        <div id="ios_dialog" className="IosDialog" >
        <div className='IosText'>Klicken Sie auf Weiter</div>
        <div className='IosBtn' onClick={handeIosPlay}>Weiter</div>
        </div>
          
         <Box className='ReelsLoader' id="load-circle" ref={circle_ref}>
                <CircularProgress style={{color:'#ffffff'}} size="4em"/>
                </Box>

          
                <div className='Screen' id="screen" onTouchStart={Down} onTouchEnd={Up} onTouchMove={Move}>
        
             
        
                <swiper-container prevent-interaction-on-transition="true" direction="vertical"  slides-per-view="1" style={{height:'100%'}}>
             {/*   <img className='PlayIcon' id="playpause" src={PlayBtn}/>*/}
          <Reels/>
          </swiper-container>
        
     
          
       </div>
      
  
  
  
     
  
        </div>
        <Foot/>
        </>
     
    );

  

    function Reels(props){
/* 
      AdData.item.forEach((item)=>{
        adlist.push(item)
      })
      */

      return Data.item.map((item,index)=>{
    if(index===0){
      return(
          <>
          <swiper-slide>
     <div key={index} className='ReelContainer'>
        <div  className='ReelBody'>

        <div className='ReelsIconsTop'>
          <div id="ReelsText">Reels</div>
          <PhotoCameraOutlinedIcon sx={{fontSize:27, marginRight:'15px'}}/>
        </div>

<div className='reels-video_container'>
       <video  preload="none" src={item.enclosure._attributes.url+"#t=0.001"}  className="Reel" ref={reel0} id={"d"+index} onClick={handlePausePlay} playsInline loop></video>
       </div>
       <div className='ReelsDescr'>
            <div className='ReelsDescr-Container'>
            <div className='ReelsDescr-AccImg'>
              <img src={item.enclosure._attributes.acc_img}/>
            </div>
            <div className='ReelsDescr-AccName'>{item.enclosure._attributes.acc_name}</div>
            <div className='ReelsDescr-SubscrBtn'>Abonnieren</div>
            </div>
          </div>
          
           <div className='ReelsIcons' >
           <div className='ReelsIcons-Counts'>
           <FavoriteBorderOutlinedIcon sx={{color:'white', fontSize:27}}/>
           <div>{item.enclosure._attributes.likes}</div>
           </div>
           <div className='ReelsIcons-Counts'>
            <ModeCommentOutlinedIcon sx={{color:'white', fontSize:25}}/>
            <div>{item.enclosure._attributes.com}</div>
            </div>
            <div className='ReelsIcons-Counts'>
            <SendOutlinedIcon sx={{color:'white', transform:'rotate(333deg)',fontSize:25}}/>
            <div>{item.enclosure._attributes.rep}</div>
            </div>
            <div className='ReelsIcons-Counts'>
            <MoreHorizIcon sx={{color:'white', fontSize:20, fontSize:20}}/>

            </div>
           </div>
          

           </div>
           </div>
           </swiper-slide>

           <swiper-slide>
           <div key={index} className='ReelContainer'>
            <div className='ReelBody'>

            <div className='ReelsIconsTop Ad'>
       
       <PhotoCameraOutlinedIcon sx={{fontSize:27, marginRight:'15px'}}/>
        </div>
        <div className='reels-video_container'>
                <video preload="none" src={AdData.itemads[reelsAd].ad.url+"#t=0.001"} className="Reel" ref={myElementRef} id="reelad"  onClick={handlePausePlay} playsInline  loop> </video>
           </div>
           
                <div className='ReelsDescr'>
            <div className='ReelsDescr-Container'>
            <div className='ReelsDescr-AccImg'>
              <img src={AdData.itemads[reelsAd].ad.acc_img}/>
            </div>
            <div className='ReelsDescr-AccName'>{AdData.itemads[reelsAd].ad.acc_name}</div>
          
            </div>
          </div>
          
           <div className='ReelsIcons' >
           <div className='ReelsIcons-Counts'>
           <FavoriteBorderOutlinedIcon sx={{color:'white', fontSize:27}}/>
           <div>{AdData.itemads[reelsAd].ad.likes}</div>
           </div>
           <div className='ReelsIcons-Counts'>
            <ModeCommentOutlinedIcon sx={{color:'white', fontSize:25}}/>
            <div>{AdData.itemads[reelsAd].ad.com}</div>
            </div>
            <div className='ReelsIcons-Counts'>
            <SendOutlinedIcon sx={{color:'white', transform:'rotate(333deg)',fontSize:25}}/>
            <div>{AdData.itemads[reelsAd].ad.rep}</div>
            </div>
         
           </div>

          
                </div>   
       
                </div>
                </swiper-slide>
                </>
           )
    }

///////////////////// second ad


if(index===2 && adindex2!==null){
  return(
      <>
      <swiper-slide>
 <div key={index} className='ReelContainer'>
    <div  className='ReelBody'>

    <div className='ReelsIconsTop'>
      <div id="ReelsText">Reels</div>
      <PhotoCameraOutlinedIcon sx={{fontSize:27, marginRight:'15px'}}/>
    </div>

    <div className='reels-video_container'>
   <video preload="none" src={item.enclosure._attributes.url+"#t=0.001"}  className="Reel" ref={reel0} id={"d"+index} onClick={handlePausePlay}  playsInline loop></video>
 </div>
   <div className='ReelsDescr'>
        <div className='ReelsDescr-Container'>
        <div className='ReelsDescr-AccImg'>
          <img src={item.enclosure._attributes.acc_img}/>
        </div>
        <div className='ReelsDescr-AccName'>{item.enclosure._attributes.acc_name}</div>
        <div className='ReelsDescr-SubscrBtn'>Abonnieren</div>
        </div>
      </div>
      
       <div className='ReelsIcons' >
       <div className='ReelsIcons-Counts'>
       <FavoriteBorderOutlinedIcon sx={{color:'white', fontSize:27}}/>
       <div>{item.enclosure._attributes.likes}</div>
       </div>
       <div className='ReelsIcons-Counts'>
        <ModeCommentOutlinedIcon sx={{color:'white', fontSize:25}}/>
        <div>{item.enclosure._attributes.com}</div>
        </div>
        <div className='ReelsIcons-Counts'>
        <SendOutlinedIcon sx={{color:'white', transform:'rotate(333deg)',fontSize:25}}/>
        <div>{item.enclosure._attributes.rep}</div>
        </div>
        <div className='ReelsIcons-Counts'>
        <MoreHorizIcon sx={{color:'white', fontSize:20, fontSize:20}}/>

        </div>
       </div>
      

       </div>
       </div>
       </swiper-slide>

       <swiper-slide>
       <div key={index} className='ReelContainer'>
        <div className='ReelBody'>

        <div className='ReelsIconsTop Ad'>
   
   <PhotoCameraOutlinedIcon sx={{fontSize:27, marginRight:'15px'}}/>
    </div>
    <div className='reels-video_container'>
            <video preload="none" src={AdData.ads2[adindex2].url+"#t=0.001"} className="Reel" ref={myElementRef} id="reelad"  onClick={handlePausePlay} playsInline  loop> </video>
       </div>
       
            <div className='ReelsDescr'>
        <div className='ReelsDescr-Container'>
        <div className='ReelsDescr-AccImg'>
          <img src={AdData.itemads[adindex2].ad.acc_img}/>
        </div>
        <div className='ReelsDescr-AccName'>{AdData.ads2[adindex2].acc_name}</div>
      
        </div>
      </div>
      
       <div className='ReelsIcons' >
       <div className='ReelsIcons-Counts'>
       <FavoriteBorderOutlinedIcon sx={{color:'white', fontSize:27}}/>
       <div>{AdData.ads2[adindex2].likes}</div>
       </div>
       <div className='ReelsIcons-Counts'>
        <ModeCommentOutlinedIcon sx={{color:'white', fontSize:25}}/>
        <div>{AdData.ads2[adindex2].com}</div>
        </div>
        <div className='ReelsIcons-Counts'>
        <SendOutlinedIcon sx={{color:'white', transform:'rotate(333deg)',fontSize:25}}/>
        <div>{AdData.ads2[adindex2].rep}</div>
        </div>
     
       </div>

      
            </div>   
   
            </div>
            </swiper-slide>
            </>
       )
}



/////////////////

    else{
      return(
          
    
        <swiper-slide>
        <div key={index} className='ReelContainer'>
          <div className='ReelBody'>

          <div className='ReelsIconsTop'>
          <div id="ReelsText">Reels</div>
          <PhotoCameraOutlinedIcon sx={{fontSize:27, marginRight:'15px'}}/>
        </div>
        <div className='reels-video_container'>
       <video preload="none" src={item.enclosure._attributes.url+"#t=0.001"}   className="Reel" id={"d"+index} onClick={handlePausePlay}  playsInline loop></video>
     </div>

       <div className='ReelsDescr'>
            <div className='ReelsDescr-Container'>
            <div className='ReelsDescr-AccImg'>
              <img src={item.enclosure._attributes.acc_img}/>
            </div>
            <div className='ReelsDescr-AccName'>{item.enclosure._attributes.acc_name}</div>
            <div className='ReelsDescr-SubscrBtn'>Abonnieren</div>
            </div>
          </div>
          
           <div className='ReelsIcons' >
           <div className='ReelsIcons-Counts'>
           <FavoriteBorderOutlinedIcon sx={{color:'white', fontSize:27}}/>
           <div>{item.enclosure._attributes.likes}</div>
           </div>
           <div className='ReelsIcons-Counts'>
            <ModeCommentOutlinedIcon sx={{color:'white', fontSize:25}}/>
            <div>{item.enclosure._attributes.com}</div>
            </div>
            <div className='ReelsIcons-Counts'>
            <SendOutlinedIcon sx={{color:'white', transform:'rotate(333deg)',fontSize:25}}/>
            <div>{item.enclosure._attributes.rep}</div>
            </div>
            <MoreHorizIcon sx={{color:'white', fontSize:20, fontSize:20}}/>
           </div>

           
           </div>
           </div>
           </swiper-slide>     
       
           
           
           )
    }
         
       
           
      })
      }


   

}

 



export default ReelsNew
