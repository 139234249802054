import './style.scss';
import React,{useEffect, useState, useRef, Component, createElement, useMemo, isValidElement} from 'react';
import Button from '@mui/material/Button';
import Foot from '../Foot/FootReels'
import axios from 'axios';
import {closereels} from '../../../../Redux/action/setreels'
import {checkAdReels} from '../../../../Redux/action/setad_reels'
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Data from '../../../../data/reelsprod.json'
import AdData from '../../../../data/adsvid.json'
import { useInView  } from 'react-intersection-observer';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Loading from "../Pictures/loading.gif"
import Mute from '../Pictures/mute3.svg'

import ReelAd from  '../videos/reelad.mp4'




function ReelsNew(){

   let down=false
   let up=false
   let move=false
   let firstPlay=false
   let reelsCount=Object.keys(Data.item).length-1
   let y
   let position=0;
   let videoMute=false
 //  let observer=null
  let d0
   const [videoLoad, setVideoLoad]=React.useState(false)
   const [reelAvailable, setreelAvailable]=React.useState(false)

   
 
   const myElementRef = useRef(null);
   const reel0 = useRef(null);
   const circle_ref=useRef(null)

 
  
  let videoFocus;

   let urlParam=document.location.search;
   let searchParams = new URLSearchParams(urlParam);
   let reelsAd=searchParams.get("v")
  // let reelsFormat=searchParams.get("rwf")
   //let reelsAd={ReelAd}
   let reelsFormat=20
   let adlist=[]

   //Image Ad
   let imgAd=document.createElement('img')
   
   //imgAd.src=`https://drive.google.com/uc?export=preview&id=${reelsAd}`
   
   imgAd.className="ReelImg"
   imgAd.id="imgAd"
   
   let test=Data.item[0].enclosure._attributes.url

  
      let Interval
      let videos=[]
     let swipe=true


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
      }); 

    const open = useSelector((state)=>state.reels.value)
    const checked = useSelector((state)=>state.adreels.value)

   const dispatch = useDispatch()

    const handleClose=()=>{
      clearInterval(Interval)
      setVideoLoad(false)
        dispatch(closereels())
        exitFullScreen()
        
    }

    function nextReels(){
      reelsCount++
      if(reelsCount>=Object.keys(Data.item).length){
        reelsCount--
    }
    
    
    }

    function previousReels(){
      reelsCount--
      
    }



     
    
    function Down(e){

     


      
    
        down=true
        up=false
       

     

    
      }


       
      
       function Up(e){
       
        firstPlay=true
        up=true
        down=false
        move=false
        swipe=true
        
       

         
       }

      
       function pausePreviousVideo(){
        videoFocus.pause()
       }


      
        function Move(e){
        
        move=true
        swipe=false
        document.getElementById('mute').style.opacity=0
       
       }

       const handlePause=(e)=>{
          e.target.pause()
          
       }

       const handlePlay=(e)=>{
     
        if(swipe)
        e.target.play()
      }

       const handleMute =(e)=>{
       // setMute(!videoMute)
      
       videoMute=!videoMute
        e.target.muted=videoMute
        videoMute ? document.getElementById('mute').style.opacity=0.7: document.getElementById('mute').style.opacity=0
    
       }

      
       const openVideos=async()=>{
        
        await new Promise((resolve,reject)=>{ 
         Interval=setInterval(()=>{
       
      
         if(reel0.current.readyState>=4){
          
          myElementRef.current.oncanplaythrough=function(){
            document.getElementById('screen').style.overflowY="scroll"
          }
          clearInterval(Interval)
          
          setVideoLoad(true)
           
           
          
           reel0.current.play()
           document.getElementById('screen').style.pointerEvents="auto"
           
           resolve()
         }
         else{
          
         }
      
         
      
           },1000)
      
           
          })
      
      
          }
            
      
        const Launch=async()=>{
          
           openVideos()
          .then(result=>{
            setTimeout(() => {
              document.getElementById("ios_dialog").style.display="flex"
             
            }, 1000);
            
          })
          .catch(error=>{
           
          })
      
      
        }
      
        const Launch_ios=()=>{
          openVideos()
          .then(result=>{
            setTimeout(() => {
              document.getElementById("ios_dialog").style.display="flex"
             
            }, 1000);
            
          })
          .catch(error=>{
           
          })
         
        }
      
      
        const handeIosPlay=()=>{
      
        document.getElementById("ios_dialog").style.display="none"
        
       
        PlayVideos()
        
      
        }
      
      
      
      
        
      
          
          function PlayVideos(){
      
            reel0.current.play()
            const allreels=document.querySelectorAll('.Reel')
          
            const callback=function(entries, observer){
          
              
                    
              entries.forEach(entry=>{
               
               
                let {target}=entry
          
              
               
                
                    videoFocus=target
                    pausePreviousVideo()
                   
                
          
                if(entry.isIntersecting && entry.intersectionRatio>=0.5){
              
                  target.muted=false
                  target.currentTime=0
                 target.play()
                 circle_ref.current.style.display="none"
                 swipe=true
              
                  
                }
                
              })
             
            }
            
            
            const options={
              threshold: 0.6,
             
              
              
            }
            
              const observer=new IntersectionObserver(callback,options)
             
              
              
               
               allreels.forEach((all)=>{
               videos.push(all)
             
                observer.observe(all)
                all.play()
                all.pause()
               })
               
               
         
            
             
            }

       

       
   


  function check(element) {
    return new Promise((resolve,reject) => {
     if(element!=null){

        resolve();
       
      }
      else{
        reject()
      }
    });
  }

  async function checkElement(){
    const element=reel0.current
    await check(element)
    .then(result=>{

      setreelAvailable(true)
    })
    .catch(error=>{
      checkElement()
    })
  }


  function activateFullScreen() {
    const element = document.documentElement; // Получаем элемент, представляющий всё окно браузера
    
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { /* IE/Edge */
      element.msRequestFullscreen();
    }
  }

  function exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  }


  useEffect(()=>{
   

    if(open && /Android/.test(window.navigator.userAgent)){
      
      
     
      checkElement()
      if(reelAvailable===true){
        
        Launch()
      
       
      }
  
  
    }
    else if(open && !/Android/.test(window.navigator.userAgent)){
      /*Тест для IOS*/
  
      checkElement()
      if(reelAvailable===true){
        
        
        Launch_ios()
      }
     
    }
   
  },[open,reelAvailable])














  


    return(
   
  
        <div className="Main" id="main">
     
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
         
          id="image"
          
        >
          
          <AppBar sx={{ position: 'relative', backgroundColor:'transparent', boxShadow:'0px 0px'}}>
  
          <Toolbar>
              <IconButton
                edge="start"
                onClick={handleClose}
                aria-label="close"
                style={{right:0, position:'absolute', color:'#868686'}}
              >
                <CloseIcon />
              </IconButton>
             
            </Toolbar>
            
          </AppBar>

          <Box className={videoLoad ? 'Off' : 'ReelsLoader'} id="load-circle" ref={circle_ref}>
                <CircularProgress style={{color:'#ffffff'}} size="4em"/>
                </Box>
          
          
          <div className={videoLoad ? 'Screen' : 'Off'} id="screen" onTouchStart={Down} onTouchEnd={Up} onTouchMove={Move}>
        
          <img className='MuteIcon' id="mute" src={Mute}/>
            
       <Reels/>
            
    
         
     
            <Foot/>
       </div>
      
  
  
        </Dialog>
  
     
  
        </div>
       
     
    );

  

    function Reels(props){

      AdData.item.forEach((item)=>{
        adlist.push(item)
      })

      return Data.item.map((item,index)=>{
    if(index===0){
      return(
          
    <div key={index}>
        <div  className='ReelBody'>
       <video src={item.enclosure._attributes.url}  className="Reel" ref={reel0} id={"d"+index} onClick={handleMute} onTouchStart={handlePause} onTouchEnd={handlePlay} playsInline loop>
     
           </video>
          

           </div>

            <div className='ReelBody'>
           
                <video src={adlist[reelsAd].ad.url} className="Reel" ref={myElementRef} id="reelad" playsInline onClick={handleMute} muted loop>
           
           
           </video>
                </div>   
       
                </div>
           
           )
    }
    else{
      return(
          
    
        <div key={index} >
          <div className='ReelBody'>
       <video src={item.enclosure._attributes.url}   className="Reel" id={"d"+index} onClick={handleMute} onTouchStart={handlePause} onTouchEnd={handlePlay} playsInline loop>
     
           </video>
           </div>
           </div>     
       
           
           
           )
    }
         
       
           
      })
      }


   

}

 



export default ReelsNew
