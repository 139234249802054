import './App.scss';
import StoryDemo from './Components/Demo/Story/App'
import FeedDemo from './Components/Demo/Feed/App'
import ReelsDemo from './Components/Demo/Reels/App'
import Reels from './Components/Incheck/Reels/App'
import Feed from './Components/Incheck/Feed/App'
import Story from './Components/Incheck/Story/App'
import React,{useEffect, useState, Component} from 'react';
import { BrowserRouter as Router,Route, Routes } from 'react-router-dom';

import Impressum from './Impressum'

function Main() {


  return (

    <div className='MaintainDiv'>
      <div className='MaintainLogoText'>Incheck</div>
      <div className='Maintaintext'>Die Webseite ist gerade nicht erreichbar</div>
    </div>
    
  );
}

export default Main;
