import './styles.scss';
import React,{useEffect, useState, Component,useRef} from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import Data from '../../../../data/rss_new.json'
 
import Icon from '../Pictures/logo.svg'
import Mute from '../Pictures/mute4.svg'
import Alternative from '../Pictures/your_ad.png'
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import Noimage from '../Pictures/noimage.png'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
 
function BodyInstStory(){
 
 
   
   
    // let datas=Data.rss.channel.item
     let mute=true
     let urlParam=document.location.search;
     let searchParams = new URLSearchParams(urlParam);
     let feedAd=searchParams.get("v")
     let feedFormat=searchParams.get("fwf")
     let interval
     let video=document.createElement('video')
     let source=document.createElement('source')
     source.src=`https://drive.google.com/uc?export=preview&id=${feedAd}`
     //video.src=`https://drive.google.com/uc?export=preview&id=${feedAd}`
     video.className="Pic"
     video.id="videoad2"
     //video.autoplay=true
     video.muted=mute
     video.playsInline=true
     video.controls=false
     video.loop=true
     let adlist=[]
     video.onclick=function(){
          mute=!mute
          this.muted=mute
          if(mute){
               document.getElementById("feedad2").style.display="block"
          }
          else{
               document.getElementById("feedad2").style.display="none"
          }
         
     }
     const refAd = useRef(null);
     //const[videoplay, setVideoPlay]=useState(true)
     let videoVolume=false
 
    setTimeout(()=>{
 
   
 
 
 
   
    document.getElementById("loader2").style.display="none"
 
},500)
     
       
     function clearInterfal(){
          clearInterval(interval)
     }
 
 
     const handleChangeVolume=(e)=>{
          videoVolume=!videoVolume
          console.log(videoVolume)
          if(videoVolume){
             
               e.target.muted=false
              e.target.volume=1
              document.getElementById("feedad2").style.opacity=0
             
          }
          else{
             
               
               e.target.volume=0
               document.getElementById("feedad2").style.opacity=1
          }
     }
 
 
     useEffect(() => {
               
         
       
       
         
 
          /*
          if(feedFormat==20){
 
               document.getElementById("2").insertAdjacentElement('afterend', video)
               document.getElementById("videoad2").append(source)
               document.getElementById("2").remove()
               interval=setInterval(()=>{
                 
                    if(document.getElementById("videoad2").readyState==4){
                         document.getElementById("loader2").className='loaderIconNone'
                         clearInterfal()
                       
                    }
                    else{
 
                    }
               })
     
             
          }
          else{
               document.getElementById("loader2").className='loaderIconNone'
          }
          */
         }, []);
 
         
 
         return Data.posts.map((item,index)=>{
       
             
 
        return(
 
           
                <div className='Post'  key={index} style={{pointerEvents:'none'}}>
               
                <div className='Icon_Name' id="icon_name">
                 
                <div className='minPic-container'>
                    <img className='MinPic' src={Icon}/>
                 </div>
                 
               
               
                 <div className='carousel-Icon_Name-Name'>{item.username}</div>
                </div>
 
                <div style={{position:'relative'}}>
                 <img id={index} className='Pic' src={item.display_src} onError={(e)=>{e.target.onerror = null; e.target.src=Noimage}}/>
                <Box  id={`loader`+index} className={index==2 ? 'loaderIcon' : 'loaderIconNone'}>
                   <CircularProgress />
                </Box>
                <div id={`feedad`+index} className={index==2 ? 'muteIcon' : 'muteIconNone'}>
                <img  src={Mute} style={{width:'15px', marginTop:'2px'}}/>
                </div>
 
               
                </div>
                 <FavoriteBorderOutlinedIcon style={{paddingLeft: '3%'}}/>
                 <ModeCommentOutlinedIcon style={{paddingLeft: '5%'}}/>
                 <SendOutlinedIcon style={{paddingLeft: '5%', transform:'rotate(333deg)'}}/>
                 
   
                 <BookmarkBorderOutlinedIcon style={{float:'right'}}/>
 
                 <div  className={index==2 ? 'DivNone' : 'Div'}>
   
                 <p className='PostText'> <span style={{fontWeight:'bold'}}>{item.username}</span> {item.caption}</p>
               
                 <p style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>Kommentare ansehen</p>
 
                 </div>
   
                </div>
               
               
           
   
        );
 
     })
       
     
 
}
 
export default BodyInstStory
 