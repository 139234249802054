import { createSlice } from "@reduxjs/toolkit";

export const openSlice = createSlice({

    name: 'open',
    initialState:{
        value:false,
        index:null
    },

    reducers:{
        openDial:(state,action)=>{
            state.value=true
            state.index=action.payload
        },

        closeDial:(state)=>{
            state.value=false
        },
       
    }


})


export const {openDial, closeDial}=openSlice.actions

export default openSlice.reducer