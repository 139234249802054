import './App.scss';
import React,{useEffect, useState, Component} from 'react';
import Head from '../Components/Head/Head'
import BodyInst from '../Components/Body/BodyInst'
import Foot2 from '../Components/Foot/Foot2'
import Story from '../Components/Stories/Story';
import '../Components/Stories/styles.scss'
import Content from '../Components/Stories/Story_Content'
import Logo from '../Components/Pictures/logo.svg'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BrowserRouter as Router,Route, Routes } from 'react-router-dom';
import Reels from '../Components/Reels/ReelsNew'


function App() {


  useEffect(()=>{
    
    
},[])




  return (

    
   
   
     <div className='Inst'>
    
    
   
   
    
    
    <Head/>

    
    
    <div className='Story'>

    <div className='StoryItem'>
    <img className='AddStory' src={Logo}/>
    <AddCircleIcon className="AddIcon" color="primary"></AddCircleIcon>
</div>
   {/* <Story/>*/}
   </div>
  <hr style={{backgroundColor:'gray', borderWidth:'0.01px',marginBottom:'30px'}}></hr>
    <BodyInst/>
  {/* <Body/>*/}

{/* 
    <Reels/>
    <Content/>
    */}
   
 
    <Foot2/>
   
    
    
    
    </div>
    

    
    
  );
}

export default App;
