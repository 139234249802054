import './styles.scss';
import React,{useEffect, useState, Component} from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';





function Foot(){
   

    return(

        <div className='FootReels'>
           <div className='Icons'>
            <HomeOutlinedIcon style={{fontSize:30, color:'white'}}/>
            <SearchOutlinedIcon style={{fontSize:30, color:'white'}}/>
            <VideoLibraryIcon style={{fontSize:30, color:'white'}}/>
            <ShoppingBagOutlinedIcon style={{fontSize:30, color:'white'}}/>
            <AccountCircleOutlinedIcon style={{fontSize:30, color:'white'}}/>
            </div>
        </div>

    );
}

export default Foot