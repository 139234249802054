import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Main from './Main'
import Maintenance from './Maintenance'
import reportWebVitals from './reportWebVitals';
import store from './Redux/store'
import {Provider} from 'react-redux'
import Impressum from './Impressum'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <Provider store={store}>
    <Main />
    </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// <React.StrictMode>
reportWebVitals();
