import './styles.scss';
import React,{useEffect, useState, Component} from 'react';

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Button from '@mui/material/Button';
import axios from 'axios';
import Name from '../Pictures/name.svg' 



function Head(){


    function Update(){
        axios.get("/update").then((response)=>{
          console.log(response)
        }).catch(error=>{
            console.log(error)
        })
       
        
      }
      
    function Download(){
       
         window.open('https://youtcheckserver.herokuapp.com:443/download')
       
      }


    return(

        
        <div className='Head'>
           
           {/*  <img className="Logo" src={Logo}/> */}
           <div className='logo_name_container'><img src={Name}/></div>
         {/* <p className='Logo'>Incheck</p>*/}
         <div className='head_icons_container'>
         
         <div>   <FavoriteBorderOutlinedIcon style={{fontSize:30, float:'right', marginRight:30, marginTop:5}}/> </div>
         <div> <SendOutlinedIcon style={{fontSize:30, float:'right', marginRight:10, transform:'rotate(333deg)'}}/> </div>
         </div> 
        </div>
    );
}

export default Head