import './App.scss';
import React,{useEffect, useState, Component} from 'react';
import Head from '../Components/Head/Head'
import BodyInst from '../Components/Body/BodyInstStory'
import Foot2 from '../Components/Foot/Foot2'
import Story from '../Components/Stories/Story';
import '../Components/Stories/styles.scss'
import Content from '../Components/Stories/Story_Content'
import Logo from '../Components/Pictures/logo.svg'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BrowserRouter as Router,Route, Routes } from 'react-router-dom';
import Reels from '../Components/Reels/ReelsNew'
import { useDispatch, useSelector } from 'react-redux';
import {openDial} from '../../../Redux/action/setopen'

function App() {



  const dispatch = useDispatch()

 

  useEffect(()=>{
    
    document.title = `Instacheck`;
    
    document.body.style.overflow='hidden'
    document.body.style.touchAction='none'
 
    window.addEventListener('scroll', function() {
      window.scrollTo(0, 0);  
   
  });
},[])



const launch=()=>{
      
  document.getElementById("story-playContent-Dialog").style.display="none"
 



//TimeLineStart()
dispatch(openDial(0));  

}


  return (

    
   
   
     <div className='Inst'>
    
    
    <div className="story-playContent-dialog" id="story-playContent-Dialog">
      <div className="story-playContent_btn-text">Klicken Sie auf "Weiter"</div>
      <div className="story-playContent_btn-container"><div className="story-playContent_btn" onClick={launch}>Weiter</div></div>
    </div>
   
    
    
    <Head/>

    
    
    <div className='Story'>

    <div className='StoryItem'>
    <img className='AddStory' src={Logo}/>
    <AddCircleIcon className="AddIcon" color="primary"></AddCircleIcon>
</div>
    <Story/>
   </div>
  <hr style={{backgroundColor:'gray', borderWidth:'0.01px'}}></hr>
    <BodyInst/>
  {/* <Body/>*/}

    <Reels/>
    <Content/>
    
   
 
    <Foot2/>
   
    
    
    
    </div>
    

    
    
  );
}

export default App;
