import React,{useEffect, useState, Component} from 'react';
import './styles.scss'

import {openDial,closeDial} from '../../../../Redux/action/setopen'
import {android,ios} from '../../../../Redux/action/setplattform'
import { useDispatch, useSelector } from 'react-redux';
import Data from '../../../../data/data.json'
import Data1 from '../../../../data/data1.json'
import Stories from '../../../../data/stories.json'
import Noimage from '../Pictures/noimage.png'


function Story(){

   
    const open = useSelector((state)=>state.open.value)
    const plattform = useSelector((state)=>state.plattform.value)
    const dispatch = useDispatch()
    let array=[];
    let userAgent = window.navigator.userAgent;

    Data.rss.channel.item.forEach(function(item,index){
        array.push(item.enclosure._attributes)
    })
  
    array.splice(0,15)

    useEffect(()=>{

        if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
            dispatch(ios())
               
            }

    },[])

    /* 
    const handleOpenStory=()=>{
        dispatch(openDial());
        
    }
    */

    function handleOpenStory(e){
        e.preventDefault();
        dispatch(openDial(e.currentTarget.dataset['key']));
      
    }
    

       return Stories.item.map(({enclosure,description},index)=>{
     
        
        
        return(
         
                

               
                <div id="storyitem" className='StoryItemDemo' onClick={handleOpenStory} data-key={index} key={index}>
                    <img className='StoryIconPicItem' src={enclosure._attributes.icon} onError={(e)=>{e.target.onerror = null; e.target.src=Noimage}}/>
                    <div className='TextStory'><p>{enclosure._attributes.acc}</p></div>
                </div>
              
                
             
               
               
            )
            
       })

   
}

export default Story