import React,{useEffect, useState, Component, useRef} from 'react';
import './styles.scss'
import Button from '@mui/material/Button';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {openDial,closeDial} from '../../../../Redux/action/setopen'
import { useDispatch, useSelector } from 'react-redux';
import {checkAd} from '../../../../Redux/action/setad'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import Data from '../../../../data/stories.json'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ads from '../../../../ads.json'
import adsvid from '../../../../data/adsvid.json'
import Ad from '../Pictures/your_ad.png'

import { register } from 'swiper/element/bundle';
import { Visibility } from '@mui/icons-material';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props}/>;
});



function Story_Content(){

   let imgAds=[]
   let vidAds=[]
   //let picturendex=0
   let duration=15
   let TimeInterval
   let time=0
   let array=[]
   const storyScreen = useRef(null);
   let down=false
   let up=false
   let move=false
  let storyWidth=0
  let storyPicWidth=0
  let scrollcount=0
  register();
  let storyScrollElement
  const swiperRef = useRef(null);
   let swipe=false
  
   let newCoords=0;
   let div=document.createElement('div')
   div.className="BlackDiv"
   div.id="blackdiv"
   let urlParam=document.location.search;
  let searchParams = new URLSearchParams(urlParam);
  let storyAd=0
  let storyFormat=15
  let video=document.createElement('video')
let swiper
  video.className="VideoAd"
  video.id="videoad"
  video.autoplay=true
  video.muted=true
  video.playsInline=true
  const checkStoryAd=useSelector((state)=>state.check.value)
  let progress=0
  let pictAd=document.createElement('img')
  pictAd.className="StoryImage"
  pictAd.id="pictAd"
  let sliderCount
  let start=0
let end=0
let rotation=0
let switcher=false;
let translateWidth=(window.screen.width/2)+1




//const[plattform, setPlattform]=useState(false)

   Data.item.forEach(function(item,index){
    array.push(item)
   })


   const open = useSelector((state)=>state.open.value)
   const picturendex =useSelector((state)=>state.open.index)
   const plattform = useSelector((state)=>state.plattform.value)
   const dispatch = useDispatch()

  const handleClose = () => {
    clearInterval(TimeInterval)
    dispatch(closeDial())
   //document.exitFullscreen()
    div.remove()
  };


  







/* 
   function TimeLineVideoAd(){
    
   
   
    
      if(document.getElementById('videoad')!=null){
        clearInterval(TimeInterval)
      
       let Interval=setInterval(()=>{
          if(document.getElementById('videoad').readyState==4){
            document.getElementById('videoad').style.display="block"
            document.getElementById('image').style.pointerEvents="auto"
            clearInterval(Interval)
            duration=document.getElementById('videoad').duration
            
            document.getElementById('videoad').load()
            TimeLineStart()
            progress=0
            time=0
          }
       },1000)
      
      }
      
      
   }

   */



  if(open===true){
   
    console.log(picturendex)
   

  
    if(document.getElementById('videoad')!=null){
      clearInterval(TimeInterval)
    
     let Interval=setInterval(()=>{
  

        if(document.getElementById('videoad').readyState>=1){
          
          
          
          document.getElementById('image').style.pointerEvents="auto"
          clearInterval(Interval)
          
          duration=document.getElementById('videoad').duration
         
          TimeLineStart()
          progress=0
          time=0
        }
     },1000)
    
    }
    if(storyFormat==15){
      progress=0
          time=0
          duration=15
      TimeLineStart()
      
    }
    
    

    
}
else{
  clearInterval(TimeInterval)
}


function pauseTimeLine(){
  clearInterval(TimeInterval)

}



 
function nextSlide(){
  
  const swiperEl = document.querySelector('swiper-container');
 
 

  if(swiperEl.swiper.activeIndex>=sliderCount.length-1){
    handleClose()
  }
  start=0
  time=0
  progress=0
 
 
    swiperEl.swiper.slideNext()
    swiperEl.swiper.slideChange()
   
    
    clearInterval(TimeInterval)
}

function prevSlide(){
  
  const swiperEl = document.querySelector('swiper-container');
 
 

  if(swiperEl.swiper.activeIndex>=sliderCount.length-1){
    handleClose()
  }
  start=0
  time=0
  progress=0
 
 
    swiperEl.swiper.slidePrev()
    swiperEl.swiper.slideChange()
    
    clearInterval(TimeInterval)
}


function TimeLineStart(){


  //    НЕ УДАЛЯТЬ!!!
 
  
  clearInterval(TimeInterval)
 
  
    
  TimeInterval=setInterval(()=>{
    
  
  if(time>duration){
    nextSlide()
    
  }
 
     time=time+(duration/100)// 0.15 (15 sec)
     progress++
     document.getElementById("timeremains").style.width=progress + "%"
     
   },duration*10) //150  (15 sec) 
  
  
}





async function checkElement(){
  await new Promise((resolve,reject)=>{
    
    if(document.getElementById("storyscreen")!=null){



      sliderCount = document.querySelectorAll('swiper-slide');

      const swiperEl = document.querySelector('swiper-container');
      
      
      const firstSlide = swiperEl.swiper.slides[0];
      const firstVideo=firstSlide.childNodes[1].childNodes[0].childNodes[1].childNodes[0]
      if(firstSlide.childNodes[1].childNodes[0].childNodes[1].childNodes[0].dataset.type=="video/mp4"){

      firstVideo.play()
     
   
  
      firstVideo.onloadedmetadata = function() {

        clearInterval(TimeInterval)
        start=0
        time=0
        progress=0
        duration=Math.round(firstVideo.duration)
        TimeLineStart()
      }
     
    

     
      
       
      }
     
    
    
    swiperEl.swiper.on('slideChange',()=>{
      const activeSlide = swiperEl.swiper.slides[swiperEl.swiper.activeIndex];
      const activeSlideDataset = activeSlide.childNodes[1].childNodes[0].childNodes[1].childNodes[0].dataset.type
      const allVideos=activeSlide.querySelectorAll('.StoryVideo')

      if(activeSlideDataset!="image/jpeg"){
      allVideos.forEach(video=>{
        video.play()

        clearInterval(TimeInterval)
        start=0
        time=0
        progress=0
        duration=Math.round(video.duration)
        TimeLineStart()
        
     
      })


      const slidenumm=swiperEl.swiper.activeIndex
      swiperEl.swiper.slides.forEach((itemslide,index)=>{
        if(index!=slidenumm){
          const videosToPause = itemslide.querySelectorAll('.StoryVideo');
          videosToPause.forEach(video=>{
            video.pause()
            video.currentTime=0
          })
        }
      })
    }
    else{
      const allVideos=document.querySelectorAll('.StoryVideo')

      allVideos.forEach(video=>{
        
        video.pause()
        video.currentTime=0
      })
      clearInterval(TimeInterval)
      start=0
      time=0
      progress=0
      duration=15
      TimeLineStart()
     
    }
    })

      storyScrollElement=document.getElementById("storyscreen")
      const allstory=document.querySelectorAll('.ParentDivDemo')
    
     
     
    
      storyWidth=document.getElementById("storyscreen").scrollWidth-document.getElementById("storyscreen").clientWidth
      storyPicWidth=document.getElementById("image").getBoundingClientRect().width
      
      
       
  
      // draw()
      resolve()

    }
    else{
      reject()
    }
  })
}



function check(){
  checkElement()
  .then(()=>{
    document.getElementById("storyscreen").addEventListener('scroll', function(){
      swipe=false
      pauseTimeLine()
     
    })
  })
  .catch(()=>{
    check()
  })
}



  
useEffect(()=>{
  if(open){

   

    TimeLineStart()
    

  
    check()

  
    
  
  

  }

 


ads.item.map((item,index)=>{
  imgAds.push(item.ad["url"])
 
})



video.src=vidAds[storyAd]

pictAd.src=imgAds[storyAd]



//document.documentElement.requestFullscreen()
},[open])




 
 let translation=0

{/*overflowX:'scroll', whiteSpace:'nowrap' */}

if(picturendex!=0){
  //picturendex++
}






  return (


   
     
      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{background:'#000000', zIndex:2, overflowY:'hidden', height:'100%'}}
        
        className="Dialog"
      
      >


<div style={{height:'100%'}}>
<div className="TimeLine" id="timeline">
        <div id="timeremains" className='TimeRemains'>

        </div>
        </div>

           




<div className='StoryScreen' ref={storyScreen} id="storyscreen">

{/*autoplay-delay="16000"*/}

<swiper-container slides-per-view="1"   effect={plattform ? "none" : "cube"} grab-cursor="true" cube-effect-shadow="true"
    cube-effect-slide-shadows="true"  cube-effect-shadow-scale="0.94" initial-slide={picturendex} style={{height:'100%', width:'100%'}} ref={swiperRef}>



<StoryItem/>


</swiper-container>
     


</div>

<div className='BlackDiv'></div>

</div>


      </Dialog>

    

     
    
  );






  function StoryItem(){
  
    return  Data.item.map((item, index)=>{
    
      if(index===parseInt(picturendex)){
      return(

        <>
           <swiper-slide>
           <div className='story-clickTrigger-prev' onClick={prevSlide}></div>


     <div className='StoryIndex' key={index} >

      <div id="image" className='ParentDivDemo' >
      
      <AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'30px'}}>
      

        <div className='StoryIcon'>
        {item.enclosure._attributes.type=="video/mp4" ?  <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.icon}/>: <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.url}/>}
      
        
        <div id="headText" style={{color: 'whitesmoke',position: 'absolute',left: '50px', fontSize:'18px', fontWeight:500}}>{item.enclosure._attributes.acc}</div>

        </div>
      <Toolbar>
          <IconButton
            edge="start"
          
            onClick={handleClose}
           
            aria-label="close"
            style={{right:0, position:'absolute', color:'#868686'}}
          >
            <CloseIcon />
          </IconButton>
         
        </Toolbar>
        
      </AppBar>
      
      <div id="storycontent" className='StoryContent'>

        
      {item.enclosure._attributes.type=="video/mp4" ? <video src={item.enclosure._attributes.url+"#t=0.001"} data-type={item.enclosure._attributes.type} className='StoryVideo' id={"storyvideoad"+index} playsInline loop></video> :  <img id={"storyimage"+index} data-type={item.enclosure._attributes.type} className='StoryImage' src={item.enclosure._attributes.url} />}
     

      

      
            
</div>



<div className='Message_Icon'>
<div className='Message'>


<p style={{lineHeight:0.1, position:'relative', left:'5%'}}>Nachricht senden</p>

</div>

<SendOutlinedIcon style={{position:'absolute', color:'white', right:'5%', top:'23%', transform:'rotate(333deg)'}}/>
<FavoriteBorderOutlinedIcon style={{position:'absolute',color:'white', right:'18%', top:'30%'}}/>

</div>

</div>



</div>
<div className='story-clickTrigger-next' onClick={nextSlide}>

</div>
</swiper-slide>


<swiper-slide>
<div className='story-clickTrigger-prev' onClick={prevSlide}>

</div>
<div className='StoryIndex' key={index} >


<div className='ParentDivDemo'>
      
<AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'30px'}}>
      

        <div className='StoryIcon'>
          
        <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.icon}/>
        
        <div id="headText" style={{color: 'whitesmoke',position: 'absolute',left: '50px', fontSize:'18px', fontWeight:500}}>Anzeige</div>
        </div>
      <Toolbar>
          <IconButton
            edge="start"
          
            onClick={handleClose}
           
            aria-label="close"
            style={{right:0, position:'absolute', color:'#868686'}}
          >
            <CloseIcon />
          </IconButton>
         
        </Toolbar>
        
      </AppBar>
      
      <div id="storycontent" className='StoryContent'>
       
        
       
      <video src={adsvid.itemads[0].ad.url+"#t=0.001"}  className='StoryVideo' id="storyvideoad" playsInline loop></video>
      {/* <img src={Ad} className='StoryImage' id="storyimage" /> */}
       
           
           
        
   
            
</div>


</div>



</div>
<div className='story-clickTrigger-next' onClick={nextSlide}>

</div>
</swiper-slide>


</>

)
      
      }
      else{

        return(
   
          <swiper-slide>
            <div className='story-clickTrigger-prev'onClick={prevSlide}>

</div>
            <div className='StoryIndex' key={index} >
<div  className='ParentDivDemo' key={index}>
      
<AppBar sx={{ position: 'absolute', backgroundColor:'transparent', boxShadow:'0px 0px',top:'30px'}}>
      

        <div className='StoryIcon'>
        {item.enclosure._attributes.type=="video/mp4" ?  <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.icon}/>: <img className='StoryIconPic' id="storyiconpic" src={item.enclosure._attributes.url}/>}
       
       
        <div id="headText" style={{color: 'whitesmoke',position: 'absolute',left: '50px', fontSize:'18px', fontWeight:500}}>{item.enclosure._attributes.acc}</div>
        </div>
      <Toolbar>
          <IconButton
            edge="start"
          
            onClick={handleClose}
           
            aria-label="close"
            style={{right:0, position:'absolute', color:'#868686'}}
          >
            <CloseIcon />
          </IconButton>
         
        </Toolbar>
        
      </AppBar>
      
      <div id="storycontent" className='StoryContent'>
       
        
       
      
      {item.enclosure._attributes.type=="video/mp4" ? <video src={item.enclosure._attributes.url+"#t=0.001"} data-type={item.enclosure._attributes.type} className='StoryVideo' id={"storyvideoad"+index} playsInline loop></video> :  <img id={"storyimage"+index} data-type={item.enclosure._attributes.type} className='StoryImage' src={item.enclosure._attributes.url} />}
      

      
            
</div>



<div className='Message_Icon'>
<div className='Message'>


<p style={{lineHeight:0.1, position:'relative', left:'5%'}}>Nachricht senden</p>

</div>

<SendOutlinedIcon style={{position:'absolute', color:'white', right:'5%', top:'23%', transform:'rotate(333deg)'}}/>
<FavoriteBorderOutlinedIcon style={{position:'absolute',color:'white', right:'18%', top:'30%'}}/>

</div>

</div>
</div>
<div className='story-clickTrigger-next' onClick={nextSlide}>

</div>
</swiper-slide>


)

      }

    })
  
  }

 

}


export default Story_Content
