import './styles.scss';
import React,{useEffect, useState, Component,useRef} from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import Data from '../../../../data/rss_new.json'
import AdData from '../../../../data/adsvid.json'
import Icon from '../Pictures/logo.svg'
import Mute from '../Pictures/mute4.svg'
import Alternative from '../Pictures/your_ad.png'
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import Noimage from '../Pictures/noimage.png'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import CarouselAds from '../../../../data/carousel_ads.json'
import { register } from 'swiper/element/bundle';
import { Margin } from '@mui/icons-material';




register();
function BodyInst(){
  

   
   
     //let datas=Data.rss.channel.item
     let mute=true
     let urlParam=document.location.search;
     let searchParams = new URLSearchParams(urlParam);
     let feedAd=searchParams.get("v")
     let feedAd2=searchParams.get("v2")
   
     let interval

     let adlist=[]
    
     const refCarousel = useRef(null);
     const refCarousel2 = useRef(null);
     //const[videoplay, setVideoPlay]=useState(true)
     let videoVolume=false

     const[activeSlide, setActiveSlide]=useState(0)
     const[SlideCount, setSlideCount]=useState(0)

     const[activeSlide2, setActiveSlide2]=useState(0)
     const[SlideCount2, setSlideCount2]=useState(0)

     
     let animationPlayed=true
     let animationPlayed2=true

    
    


     
       
     function clearInterfal(){
          clearInterval(interval)
     }


     const handleChangeVolume=(e)=>{
          videoVolume=!videoVolume
          console.log(videoVolume)
          if(videoVolume){
              
               e.target.muted=false
              e.target.volume=1
              document.getElementById("feedad2").style.opacity=0
              
          }
          else{
             
               
               e.target.volume=0
               document.getElementById("feedad2").style.opacity=1
          }
     }


 
    async function AnimationStart(elem1, elem2, elem3, classname){
             
                    if(classname[1]!="fewoclassStatic"){
             
                    setTimeout(() => {
                         elem1.style.opacity=1
                         setTimeout(() => {
                              elem1.style.opacity=0
                              elem2.style.opacity=1
                             setTimeout(() => {
                               elem3.style.opacity=1
                              
                               //elem3.classList.add('feed-carousel-slide-AdCTAActive')
                             }, 2000);
                         }, 3000);
                    }, 1000);
           
               }
     }


     
          
     
    
     

     useEffect(() => {

          

          const swiperEl = document.querySelector('swiper-container');
          const swiperEl2 = document.getElementById('incheck-carousel2');
          let target=refCarousel.current
          let target2=refCarousel2.current

        
       
          setSlideCount(swiperEl.swiper.slides.length)
          setActiveSlide(swiperEl.swiper.activeIndex+1)
          setSlideCount2(swiperEl2.swiper.slides.length)
          setActiveSlide2(swiperEl2.swiper.activeIndex+1)

          swiperEl2.swiper.on('slideChange',()=>{
               setActiveSlide2(swiperEl2.swiper.activeIndex+1)
               target2=refCarousel2.current
               observer2.observe(target2)
          })

          swiperEl.swiper.on('slideChange',()=>{
            
               setActiveSlide(swiperEl.swiper.activeIndex+1)
           
                target=refCarousel.current
              
                
               
                observer.observe(target)
               
          })



          const callback =(entries, observer)=>{
               entries.forEach(entry=>{
                   const {target, isIntersecting}=entry
                   if(isIntersecting){
                 
                if(animationPlayed){
                   // AnimationStart(target.children[1],target.children[2],target.children[4],target.children[1].classList)
                    animationPlayed=false
                }
                  
                
              
                    
                   }
                  
               })
         }
          const callback2 =(entries, observer2)=>{
               entries.forEach(entry=>{
                   const {target, isIntersecting}=entry
                   if(isIntersecting){
                 
                   if(animationPlayed2){
                   // AnimationStart(target.children[1],target.children[2],target.children[4], target.children[1].classList)
                    animationPlayed2=false
                   }
                 
               
                    
                   }
                  
               })
         }
     
         const options={
          threshold: 0.8,
         
          
          
        }
     
         const options2={
          threshold: 0.8,
         
          
          
        }
     
     
          const observer=new IntersectionObserver(callback, options)
          observer.observe(target)
         
     
          const observer2=new IntersectionObserver(callback2, options2)
          observer2.observe(target2)
         
       
        
         




          
         }, []);

      //data-click-tracking="reweka_long_mitBtn_optionen"

        return Data.posts.map((item,index)=>{
       

          if(index==2){
               return(
                    <div className='Post_Carousel'  key={index}>
               
                    <div className='carousel-Icon_Name' id="icon_name">
                      
                         <div className='minPic-container'>
                        <img className='MinPic' id={CarouselAds.items[feedAd][0].item._attributes.logostyle} src={CarouselAds.items[feedAd][0].item._attributes.logo}/>
                        </div>
                    
                    
                    <div className='carousel-Icon_Name-Name'>{CarouselAds.items[feedAd][0].item._attributes.accname}</div>
                    
                    </div>
    
                    <div ref={refCarousel} style={{position:'relative'}}>
                   {/*<div className='incheck-carousel-count'>{swiperEl.swiper.activeIndex+"/"+swiperEl.swiper.slides.length}</div>*/} 
                   <div className='incheck-carousel-count'>{activeSlide+"/"+CarouselAds.items[feedAd][0].item._attributes.amount}</div>
                   
                    <div className='incheck-carousel_container'>
                    <swiper-container id="incheck-carousel" pagination="true" class="mySwiper" style={{height:'100%', position:'relative'}} slides-per-view="1">
                   
                  <Sliders/>
                  
                
                    </swiper-container>

                    <div className='ad_klicks feed-carousel-slide-AdCTA-container' data-click-tracking={CarouselAds.items[feedAd][0].item._attributes.data}>
                    <div className='feed-carousel-slide-AdCTA'><div style={{marginLeft:'10px'}}>{CarouselAds.items[feedAd][0].item._attributes.cta} </div></div>
                    <div className='feed-carousel-slide-linkBtn'>{`>`}</div>
                    </div>
                    </div>
                   
                    
                    </div>

                    <div className='carousel-buttons-foot' style={{paddingBottom:'30px'}}>
                     <FavoriteBorderOutlinedIcon style={{paddingLeft: '3%'}}/>
                     <ModeCommentOutlinedIcon style={{paddingLeft: '5%'}}/>
                     <SendOutlinedIcon style={{paddingLeft: '5%', transform:'rotate(333deg)'}}/>
                     
        
                     <BookmarkBorderOutlinedIcon style={{float:'right'}}/>
    
                     </div>

                     <div  className='DivNone'>
        
                     <div className='PostText'> <span style={{fontWeight:'bold'}}>{item.username}</span> {item.caption}</div>
                    
                     <p style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>Kommentare ansehen</p>
    
                     </div>
        
                    </div>
                    
               )
          }
          if(index==6){
               return(
                    <div className='Post_Carousel'  key={index}>
               
                    <div className='carousel-Icon_Name' id="icon_name">
                      
                    <div className='minPic-container'>
                        <img className='MinPic'  id={CarouselAds.items[feedAd2][0].item._attributes.logostyle} src={CarouselAds.items[feedAd2][0].item._attributes.logo}/>
                     </div>
                    
                    
                     <div className='carousel-Icon_Name-Name'>{CarouselAds.items[feedAd2][0].item._attributes.accname}</div>
                    </div>
    
                    <div ref={refCarousel2} style={{position:'relative'}}>
                   {/*<div className='incheck-carousel-count'>{swiperEl.swiper.activeIndex+"/"+swiperEl.swiper.slides.length}</div>*/} 
                   <div className='incheck-carousel-count'>{activeSlide2+"/"+CarouselAds.items[feedAd2][0].item._attributes.amount}</div>
                   <div className='incheck-carousel_container'>
                    <swiper-container id="incheck-carousel2" pagination="true" class="mySwiper" style={{height:'100%', position:'relative'}} slides-per-view="1">
                   
                    <Sliders2/>
                  
                    </swiper-container>

                    <div className='ad_klicks feed-carousel-slide-AdCTA-container' data-click-tracking={CarouselAds.items[feedAd2][0].item._attributes.data}>
                    <div className='feed-carousel-slide-AdCTA'><div style={{marginLeft:'10px'}}>{CarouselAds.items[feedAd2][0].item._attributes.cta} </div></div>
                    <div className='feed-carousel-slide-linkBtn'>{`>`}</div>
                    </div>

                    </div>
                    
                    
                    </div>

                    <div className='carousel-buttons-foot' style={{paddingBottom:'30px'}}>
                     <FavoriteBorderOutlinedIcon style={{paddingLeft: '3%'}}/>
                     <ModeCommentOutlinedIcon style={{paddingLeft: '5%'}}/>
                     <SendOutlinedIcon style={{paddingLeft: '5%', transform:'rotate(333deg)'}}/>
                     
        
                     <BookmarkBorderOutlinedIcon style={{float:'right'}}/>
    
                     </div>

                     <div  className='DivNone'>
        
                     <div className='PostText'> <span style={{fontWeight:'bold'}}>{item.username}</span> {item.caption}</div>
                    
                     <p style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>Kommentare ansehen</p>
    
                     </div>
        
                    </div>
                    
               )
          }
          else{
  
        return(

           
                <div className='Post'  key={index}>
               
                <div className='Icon_Name' id="icon_name">
                  
                <div className='minPic-container'>
                    <img className='MinPic' src={Icon}/>
                 </div>
                 
                
                <div className='carousel-Icon_Name-Name'>{index==2 ? 'Ad' : item.username}</div>
                </div>

                <div style={{position:'relative'}}>
                <img id={index} className='Pic' src={item.display_src} onError={(e)=>{e.target.onerror = null; e.target.src=Noimage}}/>
                <Box  id={`loader`+index} className={index==2 ? 'loaderIcon' : 'loaderIconNone'}>
                   <CircularProgress />
                </Box>
                <div id={`feedad`+index} className={index==2 ? 'muteIcon' : 'muteIconNone'}>
                <img  src={Mute} style={{width:'15px', marginTop:'2px'}}/>
                </div>

                
                </div>
                 <FavoriteBorderOutlinedIcon style={{paddingLeft: '3%'}}/>
                 <ModeCommentOutlinedIcon style={{paddingLeft: '5%'}}/>
                 <SendOutlinedIcon style={{paddingLeft: '5%', transform:'rotate(333deg)'}}/>
                 
    
                 <BookmarkBorderOutlinedIcon style={{float:'right'}}/>

                 <div className="Div">
    
                 <div className='PostText'> <span style={{fontWeight:'bold'}}>{item.username}</span> {item.caption}</div>
                
                 <p style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>Kommentare ansehen</p>

                 <div style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>{item.post_date}</div> 

                 </div>
    
                </div>
                
                
            
    
        );
     }

     })
       
     function Sliders(){
          //CarouselAds.items[feedAd2][0].item._attributes.img
          return CarouselAds.items[feedAd].map((item,index)=>{
               if(feedAd==2){ // FEWO
                    return(
                         <swiper-slide key={index}>
                         <div className='feed-carousel-slide'>
                         <div className={'feed-carousel-slide-AdText ' + CarouselAds.items[feedAd][0].item._attributes.styleclass+ ' fewoclassStaticText'}>{CarouselAds.items[feedAd][index].item._attributes.text1}</div>
          
                         <img className='feed-carousel-slide-item' src={CarouselAds.items[feedAd][index].item._attributes.img}/>
                         
                         </div>
                    </swiper-slide>
                    )
               }
               else{
               return(
                    <swiper-slide key={index}>
                    <div className='feed-carousel-slide'>
                    <div className={'feed-carousel-slide-AdText ' + CarouselAds.items[feedAd][0].item._attributes.styleclass}>{CarouselAds.items[feedAd][index].item._attributes.text1}</div>
                    <img className='feed-carousel-slide-item' src={CarouselAds.items[feedAd][index].item._attributes.img}/>
                    
                    </div>
                    </swiper-slide>
               )
          }
          })
     }
     function Sliders2(){
        
          return CarouselAds.items[feedAd2].map((item,index)=>{
               if(feedAd2==2){ // FEWO
                    return(
                         <swiper-slide key={index}>
                         <div className='feed-carousel-slide'>
                         <div className={'feed-carousel-slide-AdText ' + CarouselAds.items[feedAd2][0].item._attributes.styleclass+ ' fewoclassStaticText'}>{CarouselAds.items[feedAd2][index].item._attributes.text1}</div>
          
                         <img className='feed-carousel-slide-item' src={CarouselAds.items[feedAd2][index].item._attributes.img}/>
                         
                         </div>
                    </swiper-slide>
                    )
               }
               else{
               return(
                    <swiper-slide key={index}>
                    <div className='feed-carousel-slide'>
                    <div className={'feed-carousel-slide-AdText ' + CarouselAds.items[feedAd2][0].item._attributes.styleclass}>{CarouselAds.items[feedAd2][index].item._attributes.text1}</div>
                    <img className='feed-carousel-slide-item' src={CarouselAds.items[feedAd2][index].item._attributes.img}/>
                    
                    </div>
               </swiper-slide>
               )
          }
          })
     }
     
  
}

export default BodyInst
