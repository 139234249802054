import { createSlice } from "@reduxjs/toolkit";

export const plattformSlice = createSlice({

    name: 'plattform',
    initialState:{
        value:false
    },

    reducers:{
        android:(state)=>{
            state.value=false
        },

        ios:(state)=>{
            state.value=true
        },
       
    }


})


export const {android, ios}=plattformSlice.actions

export default plattformSlice.reducer