import { createSlice } from "@reduxjs/toolkit";

export const reelsSlice = createSlice({

    name: 'reels',
    initialState:{
        value:false
    },

    reducers:{
      
        openreels:(state)=>{
            state.value=true
        },

        closereels:(state)=>{
            state.value=false
        }
    }


})


export const {openreels, closereels}=reelsSlice.actions

export default reelsSlice.reducer