import {configureStore} from '@reduxjs/toolkit'
import openReducer from './action/setopen'
import plattformReducer from './action/setplattform'
import checkReducer from './action/setad'
import reelsReducer from './action/setreels'
import checkReelsReducer from './action/setad_reels'

export default configureStore({
    reducer: {
        open:openReducer,
        check:checkReducer,
        reels:reelsReducer,
        adreels:checkReelsReducer,
        plattform:plattformReducer

    },
})