import './styles.scss';
import React,{useEffect, useState, Component,useRef} from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import Data from '../../../../data/rss_new.json'
import AdData from '../../../../data/adsvid.json'
import Icon from '../Pictures/logo.svg'
import Mute from '../Pictures/mute4.svg'
import Alternative from '../Pictures/your_ad.png'
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import Noimage from '../Pictures/noimage.png'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'

function BodyInst(){
  

   
   
    
     let mute=true
     let urlParam=document.location.search;
     let searchParams = new URLSearchParams(urlParam);
     let feedAd=searchParams.get("v")
     let feedFormat=searchParams.get("fwf")
     let interval
     let video=document.createElement('video')
     let source=document.createElement('source')
     source.src=`https://drive.google.com/uc?export=preview&id=${feedAd}`
     //video.src=`https://drive.google.com/uc?export=preview&id=${feedAd}`
     video.className="Pic"
     video.id="videoad2"
     //video.autoplay=true
     video.muted=mute
     video.playsInline=true
     video.controls=false
     video.loop=true
     let adlist=[]
     video.onclick=function(){
          mute=!mute
          this.muted=mute
          if(mute){
               document.getElementById("feedad2").style.display="block"
          }
          else{
               document.getElementById("feedad2").style.display="none"
          }
          
     }
     const refAd = useRef(null);
     //const[videoplay, setVideoPlay]=useState(true)
     let videoVolume=false

    setTimeout(()=>{

    
    const callback =(entries, observer)=>{
          entries.forEach(entry=>{
              const {target, isIntersecting}=entry
              if(isIntersecting){
               target.play()
               
              }
              else{
               target.pause()
               target.muted=true
              }
          })
    }

    const options={
     threshold: 0.5,
    
     
     
   }


     const observer=new IntersectionObserver(callback, options)
    const target=refAd.current
    
  
    observer.observe(target)
    document.getElementById("loader2").style.display="none"

},500)
     
       
     function clearInterfal(){
          clearInterval(interval)
     }


     const handleChangeVolume=(e)=>{
          videoVolume=!videoVolume
          console.log(videoVolume)
          if(videoVolume){
              
               e.target.muted=false
              e.target.volume=1
              document.getElementById("feedad2").style.opacity=0
              
          }
          else{
             
               
               e.target.volume=0
               document.getElementById("feedad2").style.opacity=1
          }
     }
 

     useEffect(() => {
               
         }, []);

         

     return Data.posts.map((item,index)=>{
         
  
        return(

           
                <div className='Post'  key={index}>
               
                <div className='Icon_Name' id="icon_name">
                  
                <div className='minPic-container'>
                    <img className='MinPic' src={Icon}/>
                 </div>
                 
                
                
                <p className='carousel-Icon_Name-Name'>{index==2 ? 'Anzeige' : item.username}</p>
                </div>

                <div style={{position:'relative'}}>
                {index==2 ? <video id={index} ref={refAd} className='Pic' src={AdData.itemads[1].ad.url} playsInline loop muted autoPlay onClick={handleChangeVolume}/> : <img id={index} className='Pic' src={item.display_src} onError={(e)=>{e.target.onerror = null; e.target.src=Noimage}} />}
                <Box  id={`loader`+index} className={index==2 ? 'loaderIcon' : 'loaderIconNone'}>
                   <CircularProgress />
                </Box>
                <div id={`feedad`+index} className={index==2 ? 'muteIcon' : 'muteIconNone'}>
                <img  src={Mute} style={{width:'15px', marginTop:'2px'}}/>
                </div>

                
                </div>
                 <FavoriteBorderOutlinedIcon style={{paddingLeft: '3%'}}/>
                 <ModeCommentOutlinedIcon style={{paddingLeft: '5%'}}/>
                 <SendOutlinedIcon style={{paddingLeft: '5%', transform:'rotate(333deg)'}}/>
                 
    
                 <BookmarkBorderOutlinedIcon style={{float:'right'}}/>

                 <div  className={index==2 ? 'DivNone' : 'Div'}>
    
                 <p className='PostText'> <span style={{fontWeight:'bold'}}>{item.username}</span> {item.caption}</p>
                
                 <p style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>Kommentare ansehen</p>

                 </div>
    
                </div>
                
                
            
    
        );

     })
       
     
  
}

/*  OLD

return datas.map((item,index)=>{
          AdData.item.forEach((item)=>{
               adlist.push(item)
             })
             
  
        return(

           
                <div className='Post'  key={index}>
               
                <div className='Icon_Name' id="icon_name">
                  
                    <img className='MinPic' src={Icon}/>
                 
                
                
                <p className='Name'>{index==2 ? 'Ad' : item['dc:creator']['#cdata-section']}</p>
                </div>

                <div style={{position:'relative'}}>
                {index==2 ? <video id={index} ref={refAd} className='Pic' src={adlist[0].ad.url} playsInline loop muted autoPlay onClick={handleChangeVolume}/> : <img id={index} className='Pic' src={item['media:content']['-url']} onError={(e)=>{e.target.onerror = null; e.target.src=Noimage}} />}
                <Box  id={`loader`+index} className={index==2 ? 'loaderIcon' : 'loaderIconNone'}>
                   <CircularProgress />
                </Box>
                <div id={`feedad`+index} className={index==2 ? 'muteIcon' : 'muteIconNone'}>
                <img  src={Mute} style={{width:'15px', marginTop:'2px'}}/>
                </div>

                
                </div>
                 <FavoriteBorderOutlinedIcon style={{paddingLeft: '3%'}}/>
                 <ModeCommentOutlinedIcon style={{paddingLeft: '5%'}}/>
                 <SendOutlinedIcon style={{paddingLeft: '5%', transform:'rotate(333deg)'}}/>
                 
    
                 <BookmarkBorderOutlinedIcon style={{float:'right'}}/>

                 <div  className={index==2 ? 'DivNone' : 'Div'}>
    
                 <p className='PostText'> <span style={{fontWeight:'bold'}}>{item['dc:creator']['#cdata-section']}</span> {item.title['#cdata-section']}</p>
                
                 <p style={{lineHeight:1, fontSize:'small', color:'#7F7F7F', marginLeft:'3%'}}>Kommentare ansehen</p>

                 </div>
    
                </div>
                
                
            
    
        );

     })

*/

export default BodyInst
