import { createSlice } from "@reduxjs/toolkit";

export const reelsAdSlice = createSlice({

    name: 'checkreels',
    initialState:{
        value:false
    },

    reducers:{
      
        checkAdReels:(state)=>{
            state.value=false
        }
    }


})


export const {checkAdReels}=reelsAdSlice.actions

export default reelsAdSlice.reducer