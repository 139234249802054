import React from 'react'
import { register } from 'swiper/element/bundle';


import 'swiper/css';
import './style.scss'


 
function Impressum(){
 
  
   
    
        return (
            
            <div className="Impressum">
               
               <div className='Impressum Text'>
               <p className='p'>Annalect Incheck Demo</p>
               <div className='p2'><a href="/story">Incheck Stories (mobile only)</a></div>
               <div className='p2'><a href="/feed">Incheck Feed (mobile only)</a></div>
               <div className='p2'><a href="/reels">Incheck Reels (mobile only)</a></div>
               </div>

               <div className='foot'>© 2024 annalect. A brand of Omnicom Media Group. All Rights reserved. <a href="http://www.annalect.de/impressum">Impressum</a></div>
               
              
             </div>
);
  
}

export default Impressum
