import './style.scss';
import React,{useEffect, useState, useRef} from 'react';
import Foot from '../Foot/FootReels'
import {closereels} from '../../../../Redux/action/setreels'
import {checkAdReels} from '../../../../Redux/action/setad_reels'
import { useDispatch, useSelector } from 'react-redux';
import Data from '../../../../data/reels.json'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Mute from '../Pictures/mute3.svg'
import ReelAd from  '../videos/reelad.mp4'
import PlayBtn from '../Pictures/play.svg'
import Ads from '../../../../data/adsvid.json'
import { register } from 'swiper/element/bundle';

//icons

import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';

register();

function ReelsNew(){

   let down=false
   let up=false
   let move=false
   let firstPlay=false
   let reelsCount=Object.keys(Data.item).length-1
   let y
   let position=0;
   let videoPause=false
 
  let d0
   const [videoLoad, setVideoLoad]=React.useState(false)
   const [reelAvailable, setreelAvailable]=React.useState(false)
   const [iosDialogstate, setiosDialogstate]=React.useState(false)


 
   const myElementRef = useRef(null);
   const reel0 = useRef(null);

   const circle_ref=useRef(null)

 
  
  let videoFocus;

   let urlParam=document.location.search;
   let searchParams = new URLSearchParams(urlParam);

   let reelsAd={ReelAd}
   let reelsFormat=20
   

   //Image Ad
   let imgAd=document.createElement('img')
   

   
   imgAd.className="ReelImg"
   imgAd.id="imgAd"
   
   let test=Data.item[0].enclosure._attributes.url
      let Interval
      let videos=[]
     let swipe=true




   const dispatch = useDispatch()

    const handleClose=()=>{
      clearInterval(Interval)
      setVideoLoad(false)
        dispatch(closereels())
        exitFullScreen()
        
    }

    function Down(e){

        down=true
        up=false
      
    
      }



      
       function Up(e){
       
        firstPlay=true
        up=true
        down=false
        move=false
        swipe=true
        
       

         
       }

      
       function pausePreviousVideo(){
        videoFocus.pause()
       }


      
        function Move(e){
        
        move=true
        swipe=false
        document.getElementById('playpause').style.display="none"
        videoPause=false
       
       }

       const handlePausePlay=(e)=>{
        /* 
        videoPause=!videoPause
        if(videoPause){
          e.target.pause()
          document.getElementById('playpause').style.display="block"
        }
        
       else if(!videoPause){
          e.target.play()
          document.getElementById('playpause').style.display="none"
        }
          
       */
         
       }

       const handlePlay=(e)=>{
        
        if(swipe)
        e.target.play()
       
      }

      /* 
       const handleMute =(e)=>{
       // setMute(!videoMute)
    
       videoMute=!videoMute
        e.target.muted=videoMute
        videoMute ? document.getElementById('mute').style.opacity=0.7: document.getElementById('mute').style.opacity=0
    
       }

      */
      

       

       const openVideos=async()=>{
        
  await new Promise((resolve,reject)=>{ 
   Interval=setInterval(()=>{
 

   if(reel0.current.readyState>=4){
    
    myElementRef.current.oncanplaythrough=function(){
      document.getElementById('screen').style.overflowY="scroll"
    }
    clearInterval(Interval)
    
    setVideoLoad(true)
     
     
    
     reel0.current.play()
     document.getElementById('screen').style.pointerEvents="auto"
     
     resolve()
   }
   else{
    
   }

   

     },1000)

     
    })


    }
      

  const Launch=async()=>{
    
     openVideos()
    .then(result=>{
      setTimeout(() => {
       PlayVideos()
       
      }, 1000);
      
    })
    .catch(error=>{
     
    })


  }

  const Launch_ios=()=>{
    openVideos()
    .then(result=>{
      setTimeout(() => {
        document.getElementById("ios_dialog").style.display="flex"
       
      }, 1000);
      
    })
    .catch(error=>{
     
    })
   
  }


  const handeIosPlay=()=>{

  document.getElementById("ios_dialog").style.display="none"
  
 
  PlayVideos()
  

  }




  

    
    function PlayVideos(){

      reel0.current.play()
      const allreels=document.querySelectorAll('.Reel')
    
      const callback=function(entries, observer){
    
        
              
        entries.forEach(entry=>{
         
         
          let {target}=entry
    
        
         
          
              videoFocus=target
              pausePreviousVideo()
             
          
    
          if(entry.isIntersecting && entry.intersectionRatio>=0.5){
        
            target.muted=false
            target.currentTime=0
           target.play()
           circle_ref.current.style.display="none"
           swipe=true
        
            
          }
          
        })
       
      }
      
      
      const options={
        threshold: 0.6,
       
        
        
      }
      
        const observer=new IntersectionObserver(callback,options)
       
        
        
         
         allreels.forEach((all)=>{
         videos.push(all)
       
          observer.observe(all)
          all.play()
          all.pause()
         })
         
         
   
      
       
      }
   


  function check(element) {
    return new Promise((resolve,reject) => {
     if(element!=null){

        resolve();
       
      }
      else{
        reject()
      }
    });
  }

  async function checkElement(){
    
    const element=document.getElementById('d0')
    await check(element)
    .then(result=>{

      setreelAvailable(true)
    })
    .catch(error=>{
      checkElement()
    })
  }


  function activateFullScreen() {
    const element = document.documentElement; // Получаем элемент, представляющий всё окно браузера
    
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { /* IE/Edge */
      element.msRequestFullscreen();
    }
  }

  function exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  }


  useEffect(()=>{
   
    const swiperEl = document.querySelector('swiper-container');


    swiperEl.swiper.on('reachEnd',()=>{
      swiperEl.swiper.allowSlideNext=false
    })

    swiperEl.swiper.on('fromEdge', () => {
      console.log('Moved from the edge!');
      swiperEl.swiper.allowSlideNext = true; 
    });

    if(/Android/.test(window.navigator.userAgent)){
      
      
     
      checkElement()
      if(reelAvailable===true){
        
        Launch()
      
       
      }
  
  
    }
    else if(!/Android/.test(window.navigator.userAgent)){
      /*Тест для IOS*/
  
      checkElement()
      if(reelAvailable===true){
        
        
        Launch_ios()
      }
     
    }
   
   },[reelAvailable])
  


    return(
   
  <>
        <div className="Main" id="main">
     
       

        {/*  {iosDialogstate && <IosDialog onClick={handeIosPlay}/>}*/}
        <div id="ios_dialog" className="IosDialog" >
        <div className='IosText'>Klicken Sie auf Weiter</div>
        <div className='IosBtn' onClick={handeIosPlay}>Weiter</div>
        </div>
          
         <Box className={videoLoad ? 'Off' : 'ReelsLoader'} id="load-circle" ref={circle_ref}>
                <CircularProgress style={{color:'#ffffff'}} size="4em"/>
                </Box>

          
                <div className={videoLoad ? 'Screen' : 'Off'} id="screen" onTouchStart={Down} onTouchEnd={Up} onTouchMove={Move}>
        
       
               
         
                <swiper-container direction="vertical"  slides-per-view="1" style={{height:'100%'}}>
              {/*   <img className='PlayIcon' id="playpause" src={PlayBtn}/>*/}
          <Reels/>
    
          </swiper-container>
     
          
       </div>
      
  
  
  
     
  
        </div>
        <Foot/>
        </>
     
    );

     
     
    
     

    function Reels(props){
   

      return Data.item.map((item,index)=>{
    if(index===0){
      return(
          <>
          <swiper-slide>
    <div key={index} className='ReelContainer'>
        <div  className='ReelBody'>

        <div className='ReelsIconsTop'>
          <div id="ReelsText">Reels</div>
          <PhotoCameraOutlinedIcon sx={{fontSize:27, marginRight:'15px'}}/>
        </div>
        
        <video src={item.enclosure._attributes.url+"#t=0.001"}  className="Reel" ref={reel0} id={"d"+index} onClick={handlePausePlay}  playsInline loop> </video>
     
          <div className='ReelsDescr'>
            <div className='ReelsDescr-Container'>
            <div className='ReelsDescr-AccImg'>
              <img src={item.enclosure._attributes.acc_img}/>
            </div>
            <div className='ReelsDescr-AccName'>{item.enclosure._attributes.acc_name}</div>
            <div className='ReelsDescr-SubscrBtn'>Abonnieren</div>
            </div>
          </div>
          
           <div className='ReelsIcons'>
            <div className='ReelsIcons-Counts'>
           <FavoriteBorderOutlinedIcon sx={{color:'white', fontSize:27}}/>
           <div>{item.enclosure._attributes.likes}</div>
           </div>
           <div className='ReelsIcons-Counts'>
            <ModeCommentOutlinedIcon sx={{color:'white', fontSize:25}}/>
            <div>{item.enclosure._attributes.com}</div>
           </div>
           <div className='ReelsIcons-Counts'>
            <SendOutlinedIcon sx={{color:'white', transform:'rotate(333deg)',fontSize:25}}/>
            <div>{item.enclosure._attributes.rep}</div>
           </div>
            <MoreHorizIcon sx={{color:'white', fontSize:20, fontSize:20}}/>
           </div>
           


           </div>

        
                
                </div>
                </swiper-slide>

                <swiper-slide>

                <div key={index} className='ReelContainer'> 
                <div className='ReelBody'>

<div className='ReelsIconsTop Ad'>

<PhotoCameraOutlinedIcon sx={{fontSize:27, marginRight:'15px'}}/>
</div>

    <video src={Ads.itemads[0].ad.url+"#t=0.001"}  className="Reel" ref={myElementRef} onClick={handlePausePlay} playsInline loop>   </video>

    <div className='ReelsDescr'>
<div className='ReelsDescr-Container'>
<div className='ReelsDescr-AccImg'>
  <img src={item.enclosure._attributes.acc_img}/>
</div>
<div className='ReelsDescr-AccName'>{Ads.itemads[0].ad.acc_name}</div>

</div>
</div>

<div className='ReelsIcons' >

<div className='ReelsIcons-Counts'>
<FavoriteBorderOutlinedIcon sx={{color:'white', fontSize:27}}/>
<div>{Ads.itemads[0].ad.likes}</div>
</div>
<div className='ReelsIcons-Counts'>
<ModeCommentOutlinedIcon sx={{color:'white', fontSize:25}}/>
<div>{Ads.itemads[0].ad.com}</div>
</div>
<div className='ReelsIcons-Counts'>
<SendOutlinedIcon sx={{color:'white', transform:'rotate(333deg)',fontSize:25}}/>
<div>{Ads.itemads[0].ad.rep}</div>
</div>
<MoreHorizIcon sx={{color:'white', fontSize:20, fontSize:20}}/>
</div>

    </div>   
    </div>  
    </swiper-slide> 

                </>
           )
    }
    else{
      return(
          
        <swiper-slide>
        <div key={index} className='ReelContainer'>
          <div className='ReelBody'>

          <div className='ReelsIconsTop'>
          <div id="ReelsText">Reels</div>
          <PhotoCameraOutlinedIcon sx={{fontSize:27, marginRight:'15px'}}/>
        </div>

       <video src={item.enclosure._attributes.url+"#t=0.001"}   className="Reel" id={"d"+index} onClick={handlePausePlay} playsInline loop></video>


       <div className='ReelsDescr'>
            <div className='ReelsDescr-Container'>
            <div className='ReelsDescr-AccImg'>
              <img src={item.enclosure._attributes.acc_img}/>
            </div>
            <div className='ReelsDescr-AccName'>{item.enclosure._attributes.acc_name}</div>
            <div className='ReelsDescr-SubscrBtn'>Abonnieren</div>
            </div>
          </div>
          
           <div className='ReelsIcons' >
           <div className='ReelsIcons-Counts'>
           <FavoriteBorderOutlinedIcon sx={{color:'white', fontSize:27}}/>
           <div>{item.enclosure._attributes.likes}</div>
           </div>
           <div className='ReelsIcons-Counts'>
            <ModeCommentOutlinedIcon sx={{color:'white', fontSize:25}}/>
            <div>{item.enclosure._attributes.com}</div>
           </div>
           <div className='ReelsIcons-Counts'>
            <SendOutlinedIcon sx={{color:'white', transform:'rotate(333deg)',fontSize:25}}/>
            <div>{item.enclosure._attributes.rep}</div>
           </div>
            <MoreHorizIcon sx={{color:'white', fontSize:20, fontSize:20}}/>
           </div>
     
        
           </div>
           </div>     
           </swiper-slide>
           
           
           )
    }
         
       
           
      })
      }


   

}

 



export default ReelsNew
