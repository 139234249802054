import { createSlice } from "@reduxjs/toolkit";

export const adSlice = createSlice({

    name: 'check',
    initialState:{
        value:true
    },

    reducers:{
      
        checkAd:(state)=>{
            state.value=false
        }
    }


})


export const {checkAd}=adSlice.actions

export default adSlice.reducer
